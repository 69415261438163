import {Workarea} from './workarea.class';

export class Filter {
    project: number = null;
    workareaType = null;
    damage = null;
    klinkerfloor = null;
    min = null;
    max = null;
    toplayer = null;
    interlayer = null;
    bottomlayer = null;
    waymark = null;
    genpriority = null;
    execution = null;
    wearlayer = null;
    resetfilter = null;
    endDate = null;
    startDate = null;
    workarea = null;
    statuses: number[] = [];

    public forParams() {
        const jsonObj = JSON.parse(JSON.stringify(this));
        jsonObj.startDate = jsonObj.startDate ? new Date(jsonObj.startDate).getTime() : null;
        jsonObj.endDate = jsonObj.endDate ? new Date(jsonObj.endDate).getTime() : null;
        jsonObj.statuses = jsonObj.statuses.join(',');
        return jsonObj;
    }

    public apply(workareas: Workarea[]): Workarea[] {
        workareas = workareas.filter((p) => {
            let show = true;
            if (this.statuses?.length > 0) {
                show = this.statuses.indexOf(p.status_id) !== -1;
            }
            if (show) {
                if (!!this['damage'] && p.extra['damage'] !== this['damage']) {
                    show = false;
                }
                if (!!this['klinkerfloor']) {
                    if (p.extra['klinkerfloor'] && !this['klinkerfloor']) {
                        show = false;
                    }
                    if (!p.extra['klinkerfloor'] && this['klinkerfloor']) {
                        show = false;
                    }
                }
                if (!!this['workareaType'] && show) {
                    if (this['workareaType'] === 'Werkvakken') {
                        show = !p.has_documents;
                    }
                    if (this['workareaType'] === 'Werklocaties') {
                        show = p.has_documents;
                    }
                    if (this['workareaType'] === 'Asfaltboringen') {
                        show = p.type === 'drillings';
                    }
                }

                let showByMinMax = false;

                if (p.areas) {
                    p.areas.forEach((area) => {
                        const areaTotal = area.width * area.length;
                        if ((!this['min'] || areaTotal >= +this['min'])
                            && (!this['max'] || areaTotal <= +this['max'])
                        ) {
                            showByMinMax = true;
                        }
                    });
                }
                if (!showByMinMax &&
                    (!!this['min'] || !!this['max'])) {
                    show = false;
                }
                if (!!this['genpriority'] && p.extra['genpriority'] !== this['genpriority']) {
                    show = false;
                }

                const createdAt = new Date(p['created_at']).getTime();
                if (!!this['startDate'] && createdAt < this['startDate']) {
                    show = false;
                }
                if (!!this['endDate'] && createdAt > this['endDate']) {
                    show = false;
                }

                if (!!this['waymark'] &&
                    p.extra['waymark'] !== this['waymark'] &&
                    (this['waymark'] !== 'all' || ['Niet van toepassing', null, undefined].includes(p.extra['waymark'])) &&
                    (this['waymark'] !== 'Niet van toepassing' || !['Niet van toepassing', null, undefined].includes(p.extra['waymark']))
                ) {
                    show = false;
                }

                if (!!this['wearlayer'] && p.extra['wearlayer'] !== this['wearlayer']) {
                    show = false;
                }

                if (!!this['execution'] && p.extra['execution'] !== this['execution']) {
                    show = false;
                }

                if (!!this['toplayer'] && p.extra['toplayer'] !== this['toplayer']) {
                    show = false;
                }

                if (!!this['interlayer'] && p.extra['interlayer'] !== this['interlayer']) {
                    show = false;
                }
                if (!!this['project'] && p.townproject_id !== +this['project']) {
                    show = false;
                }
                if (!!this['workarea'] && p.id !== +this['workarea']) {
                    show = false;
                }

                if (!!this['bottomlayer'] && p.extra['bottomlayer'] !== this['bottomlayer']) {
                    show = false;
                }
            }
            return show;
        });
        return workareas;
    }
}
