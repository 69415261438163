import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Town} from '../town.class';
import {Workarea} from '../workarea.class';
import {Filter} from '../filter';
import {WorkareaService} from '../services/workarea/workarea.service';

@Component({
    selector: 'app-town-list',
    templateUrl: './town-list.component.html',
    styleUrls: ['./town-list.component.scss']
})
export class TownListComponent implements OnInit, OnDestroy {

    childActive = false;
    town: Town;
    filter = new Filter();
    workareas: Workarea[];
    filteredWorkareas: Workarea[];
    type: string;
    private subscriptions = new Subscription();
    workareaPerPage = false;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private workareaService: WorkareaService) {
    }

    ngOnInit() {
        this.reload();
    }

    public reload(reload = false) {
        Object.assign(this.filter, this.route.snapshot.queryParams);
        if (this.filter.statuses) {
            this.filter.statuses = this.route.snapshot.queryParams.statuses?.split(',').map(p => +p) ?? [];
        }
        if (this.route.snapshot.queryParams['workarea_per_page'] && this.route.snapshot.queryParams['workarea_per_page'] === 'true') {
            this.workareaPerPage = true;
        }
        if (this.route.snapshot.queryParams?.workarea) {
            this.workareaService.getDetails(this.route.snapshot.queryParams?.workarea).then(workarea => {
                this.filteredWorkareas = [workarea];
                setTimeout(() => {
                    document.body.appendChild(document.createElement('readyforpuppeteer'));
                }, 1000);
            });
        } else {
            this.workareaService.getAll(reload, this.filter.statuses).then(workareas => {
                this.workareas = workareas;
                this.filterWorkareas(this.filter);
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private filterWorkareas(filter: Filter) {
        if (this.workareas) {
            const filtered = filter.apply(this.workareas);
            if (filtered[0]) {
                this.type = filtered[0].type;
            }
            this.filteredWorkareas = filtered;
        }

        setTimeout(() => {
            document.body.appendChild(document.createElement('readyforpuppeteer'));
        }, this.filteredWorkareas?.length * 60);
    }

    areasTotal(areas) {
        let totalSize = 0;
        if (areas) {
            areas.forEach(area => {
                area.length = area.length > 0 ? area.length : 1;
                area.width = area.width > 0 ? area.width : 1;
                totalSize += area.width * area.length;
            });
        }
        return Math.round(totalSize * 100) / 100;
    }
}
