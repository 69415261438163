<div class="modal-header">
    <h4 class="modal-title pull-left">{{data.type}} exporteren</h4>
    <button mat-dialog-close="" aria-label="Close" class="close pull-right" tabindex="-1" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="form?.controls?.status" [formGroup]="form" class="modal-body">
    <div class="d-flex flex-column">
        <b>Welke statussen wil je exporteren?</b>
        <mat-checkbox *ngFor="let status of statusCounts"
                      [formControl]="form.controls.status.controls[status.id]">
            <div class="d-flex flex-row flex-nowrap gap-2 align-items-center">
                <div [innerHTML]="status.count | marker : status.colorcode" class="marker"></div>
                <span>{{status.name}}</span>
            </div>
        </mat-checkbox>
        <br>
        <b>Ieder werkvak op een nieuw pagina?</b>
        <mat-checkbox [formControl]="form.controls.workarea_per_page">Plaats werkvak op eigen pagina</mat-checkbox>

    </div>
</div>
<div class="modal-footer">
    <button mat-dialog-close color="primary" mat-button type="button">
        Sluiten
    </button>
    <div class="spacer"></div>
    <button (click)="donwload()" color="primary" mat-raised-button type="button">
        Downloaden
    </button>
</div>
